.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.imgmain
{
width:370px;
height:415px;
padding: 0px 0px 0px 50px;
margin:50px 0px 0px;
border-radius: 60%;
box-shadow: 10px 0px;
filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 50%));
}

a:link {
  color: rgb(65, 221, 4);
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: rgb(65, 221, 4);
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: rgb(247, 113, 113);
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: rgb(250, 100, 0);
  background-color: transparent;
  text-decoration: underline;
}

div.a {
  text-align: center;
}

div.b {
  text-align: left;
  line-height: 2;
}

div.c {
  text-align: right;
} 

div.d {
  text-align: justify;
} 